import Cookies from 'universal-cookie';
import { getUtm } from './lib';

const UTM_COOKIE_NAME = 'utmCookie';
const UTM_COOKIE_EXPIRES = Date.now() + 86400e3;
const ROISTAT_COOKIE_NAME = 'roistat_visit';
const YM_UID_COOKIE_NAME = '_ym_uid';
const GA_UID_COOKIE_NAME = '_gid';

const cookies = new Cookies();

export const setUtmToCookie = () => {
  const UTM_GROUP = getUtm();

  const checkForEmpty = Object.values(UTM_GROUP).filter(Boolean);

  if (checkForEmpty.length !== 0) {
    cookies.set(UTM_COOKIE_NAME, UTM_GROUP, {
      path: '/',
      expires: new Date(UTM_COOKIE_EXPIRES),
    });
  }
};

export const getUtmFromCookie = () => cookies.get(UTM_COOKIE_NAME);
export const getRoistatFromCookie = () => cookies.get(ROISTAT_COOKIE_NAME);
export const getYmUidFromCookie = () => cookies.get(YM_UID_COOKIE_NAME);
export const getGaUidFromCookie = () =>
  cookies.get(GA_UID_COOKIE_NAME)?.substring(6);
export const YM_COUNTER_WEBSITE = '50001859';

const formatUrlParams = (params) => {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join('&');
};

export const getUtmCookie = () => cookies.get(UTM_COOKIE_NAME);

export const getUtmQueryString = () => {
  const utmCookie = getUtmCookie();
  return utmCookie ? formatUrlParams(utmCookie) : '';
};
